import React, { useCallback } from 'react'
import { Row, Col } from 'react-grid-system';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import _get from 'lodash/get';
import ReactGA from 'react-ga';

import { useLocalize } from 'utils/localize';
import { slugify } from 'utils/helpers';
import { buildNextRun, palette } from 'modules/OpenPrograms/utils';
import Date from 'components/shared/Date'

import ContentBox from '../ContentBox';
import Link from '../Link';
import Image from '../Image'
import { Widget } from '../styles';

import * as S from './styles';
import OpticalHeading from 'ui/elements/Heading/OpticalHeading'
import Level from 'ui/elements/Level'
import Button from 'ui/elements/Button'
import Icon from 'ui/elements/Icon'

const ProgramTeaser = ({
  program: rawProgram,
}) => {
  const { t } = useTranslation(['program', 'common']);
  const program = useLocalize(rawProgram);
  const nextRun = buildNextRun(program);
  const to = slugify(program.title);
  const locations = _get(nextRun, 'locations', []);
  const hero = _get(program, 'hero');

  const trackCallToAction = useCallback((category, action) => {
    return () => {
      ReactGA.event({
        category,
        action,
        label: program.title
      });
    }
  }, [program])

  return (
    <Widget>
      <S.ProgramTeaser>
        <OpticalHeading level={3} renderAs="h2" color={palette.variant}>
          <Link to={`/open-programs/${to}`}>{program.title}</Link>
        </OpticalHeading>

        <Row component={S.SummaryBox}>
          <Col xs={12} lg={6}>
            {
              hero && (
                <Link
                  aria-hidden
                  to={`/open-programs/${to}`}
                >
                  <Image
                    {...hero}
                    width={980}
                    height={450}
                    alt={program.title}
                  />
                </Link>
              )
            }
          </Col>
          <Col xs={12} md={4} lg={2} component={S.Column}>
            <S.ProgramBox>
              <Icon
                name='calendar'
                size={30}
              />
              <OpticalHeading level={5}>{t('nextCourse')}</OpticalHeading>
              <S.CourseDate>
                {
                  nextRun ? (
                    <React.Fragment>
                      <Date date={nextRun.startDate} />
                      -
                      <Date date={nextRun.endDate} />
                    </React.Fragment>
                  ) : t('dateRequest')
                }
              </S.CourseDate>
            </S.ProgramBox>
          </Col>
          <Col xs={12} md={4} lg={2} component={S.Column}>
            <S.ProgramBox>
              <Icon
                name='location-pin'
                size={30}
              />
              <OpticalHeading level={5}>{t('common:location')}</OpticalHeading>

              <S.Locations>
                {
                  locations.length ? nextRun.locations.map((location, i) => (
                    <li key={i}>{location}</li>
                  )) : (
                    <li>{t('locationRequest')}</li>
                  )
                }
              </S.Locations>
            </S.ProgramBox>
          </Col>
          <Col xs={12} md={4} lg={2} component={S.Column}>
            <S.ProgramBox>
              <OpticalHeading level={5}>{t('levelShort')}</OpticalHeading>

              <Level
                heading={t('leadershipExperience')}
                value={program.leadershipExperience}
              />

              <Level
                heading={t('workExperience')}
                value={program.workExperience}
              />
            </S.ProgramBox>
          </Col>
        </Row>

        {program.landing_teaser && <ContentBox blocks={program.landing_teaser} />}
        {!program.landing_teaser && <ContentBox blocks={program.intro} />}

        <S.ButtonWrap>
          <Button
            color='darkBlue'
            variant='contained'
            label={t('common:findOutMore')}
            iconName='arrow-right'
            as={Link}
            to={`/open-programs/${to}`}
          />
          {
            program.callSchedule && (
              <Button
                color='orange'
                variant='contained'
                target='_blank'
                label={t('callSchedule')}
                iconName='arrow-right'
                onClick={trackCallToAction('Schedule a Call', 'Click')}
                as="a"
                href={encodeURI(
                  `${program.callScheduleUrl}?subject="${program.title}"`
                )}
                rel="noopener"
              />
            )
          }
        </S.ButtonWrap>
      </S.ProgramTeaser>
    </Widget>
  )
};

export default ProgramTeaser;
