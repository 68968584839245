import React, { useMemo } from 'react';
import { graphql } from 'gatsby';
import { Index } from 'elasticlunr';

import PageLayout from 'components/Layout/PageLayout';
import Seo from 'components/shared/Seo';

import { NoResults, Topics, Query } from 'modules/Search';

const Search = ({
  location,
  data
}) => {
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('q');
  const type = searchParams.get('type');
  const searchIndex = Index.load(data.siteSearchIndex.index);
  const opts = type === 'topics' ?
    {
      fields: {
        topics: { bool: 'AND', boost: 10 },
      },
    } :
    {
      expand: true,
    };

  const results = searchIndex
    .search(query, opts)
    .map(({ ref }) => searchIndex.documentStore.getDoc(ref))
    // re-filter results in case elasticlunr does not update filter
    .filter(result => result.hidden !== true && result.disabled !== true);

  const Component = useMemo(() =>{
    if (results.length === 0) {
      return <NoResults query={query} />;
    }

    if (type === 'topics') {
      return <Topics topic={query} results={results} />
    }

    return <Query query={query} results={results} />
  }, [query, results, type])

  return (
    <PageLayout>
      <Seo title={`Search results for ${query}`} />
      {Component}
    </PageLayout>
  )
};

export const query = graphql`
    query ReadSearchIndex($language: String!) {
      siteSearchIndex {
        index
      }
      locales: allLocale(filter: { language: {eq: $language} }) {
        edges {
          node {
            ns
            data
            language
          }
        }
      }
    }
`

export default Search;
