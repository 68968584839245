import React from 'react';
import Heading from 'ui/elements/Heading';
import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Link from 'widgets/Link';

import { getLink } from './utils';
import * as S from './styles';

const GroupLabel = {
  'program': 'Programs',
  'conference': 'Conferences',
  'route': 'Pages',
}

const Query = ({
  query,
  results,
}) => {
  const { t } = useTranslation('search');
  const groups = _groupBy(results, 'type');

  return (
    <S.Search>
      <Heading level={1} hidden>{t('search')}</Heading>
      {
        _map(groups, (group, key) => (
          <S.ResultsGroup key={key}>
            <Heading level={2}>
              {
                t('results', {
                  query,
                  group: GroupLabel[key],
                  total: group.length,
                })
              }
            </Heading>
            <S.ResultsList>
              {
                _map(group, (result) => (
                  <S.QueryResult key={result.title}>
                    <Link to={getLink(result)}>
                      {result.title}
                    </Link>
                  </S.QueryResult>
                ))
              }
            </S.ResultsList>
          </S.ResultsGroup>
        ))
      }
    </S.Search>
  )
};

export default Query;
