import React from 'react';
import OpticalHeading from 'ui/elements/Heading/OpticalHeading';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import ProgramTeaser from 'widgets/ProgramTeaser';

import * as S from './styles';

const Topics = ({
  topic,
  results,
}) => {
  const { t } = useTranslation('search');

  return (
    <S.Search>
      <OpticalHeading level={3} renderAs="h1">
        {t('topics', { topic, total: results.length })}
      </OpticalHeading>
      <S.TopicsResults>
        {
          results.map((result, i) => (
            <ProgramTeaser key={i} program={result.program} />
          ))
        }
      </S.TopicsResults>
    </S.Search>
  )
};

export default Topics;
