import styled from 'styled-components';

import { Level } from 'ui/elements/Level/styles'
import { Button } from 'ui/elements/Button/styles'
import { Icon } from 'ui/elements/Icon/styles'
import { Heading } from 'ui/elements/Heading/styles'

import { palette } from 'modules/OpenPrograms/utils';
import { breakpoint } from 'ui/elements/ThemeProvider'

export const ProgramTeaser = styled.div`
  margin: 30px 0;
  
  > ${Heading} {
    margin-bottom: 20px;
  }
`;

export const Column = styled.div`
  font-size: 1.4rem;
  margin-top: ${(props) => props.theme.utils.grid.gutterWidth}px;
  color: ${(props) => props.theme.palette[palette.accent]};
  text-align: center;
  
  ${breakpoint.lg`
    margin-top: 0;
  `}
`;

export const ProgramHero = styled.div`
  width: 100%;
  overflow: hidden;
  background-image: url(${(props) => props.url});
  background-position: center center;
  height: 220px;
 
  ${breakpoint.lg`
    height: 100%;
  `}
`

export const ProgramBox = styled.div`
  background-color: rgb(241, 241, 241);
  padding: 15px;
  height: 100%;
  
  ${Icon} {
    margin-bottom: 15px; 
  }  
  
  ${Level} {
    margin-bottom: 10px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const CourseDate = styled.div`
  time {
    display: inline-block;
    padding: 0 5px;
  }
  
  ${breakpoint.lg`
    time {
      display: block;
    }
  `}
`;

export const SummaryBox = styled.div`
  margin: 10px 0;
`

export const Locations = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ButtonWrap = styled.div`
  margin-top: 1.5rem;

  ${Button} {
    margin-left: 1rem;

    &:first-child {
      margin-left: 0;
    }
  }
`
