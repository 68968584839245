import styled from 'styled-components';

export const Search = styled.div`
  padding: 50px 0;
`;

export const ResultsGroup = styled.div`
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid ${(props) => props.theme.palette.primary};
`;

export const ResultsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 30px 0 0 0;
`;

export const QueryResult = styled.li`
  font-weight: 700;
  color: ${(props) => props.theme.palette.primary};
  margin: 10px 0;

  a {
    padding: 10px;
    background: ${(props) => props.theme.palette.paperAlpha};
    color: inherit;
    display: block;
    text-decoration: none;
    
    &:hover {
      background: ${(props) => props.theme.palette.paperBeta};
    }
  }
`;

export const TopicsResults = styled.div`
  margin-top: 50px;
`
