import React from 'react';
import OpticalHeading from 'ui/elements/Heading/OpticalHeading';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import * as S from './styles';

const NoResults = ({
  query,
}) => {
  const { t } = useTranslation('search');

  return (
    <S.Search>
      <OpticalHeading level={3}>
        {t('noResults', { query })}
      </OpticalHeading>
    </S.Search>
  )
};

export default NoResults;
