import { slugify } from 'utils/helpers';

export const getLink = (entry) => {
  if (entry.slug) {
    return `/${entry.slug}`;
  }

  const to = slugify(entry.title);

  if (entry.type === 'program') {
    return `/open-programs/${to}`;
  }

  if (entry.type === 'conference') {
    return `/conferences/${to}`;
  }

  return '/404';
}
